import { FormattedMessage } from "react-intl";

const ContactDemo = () => {
	return (
		<section className="section section-gray">
			<div className="container mid-container text-center">
				<div className="row">
					<div className="col">
						<h3 >
							<FormattedMessage id="contactDemo.title" />
						</h3>
						<address>
							<FormattedMessage
								id="contactDemo.description"
								values={{
									p: chunks => <p>{chunks}</p>,
									br: <br />
								}}
							/>

						</address>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ContactDemo;