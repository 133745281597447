import { FormattedMessage } from "react-intl";
import { CarouselSlide, ServiceCard } from "../../Components";

const ServicesMainArticle = () => {
	const imagesCarousel1 = [
		"/assets/img/showcases/services-page/services-page-carousel-01.jpg",
		"/assets/img/showcases/services-page/services-page-carousel-02.jpg",
		"/assets/img/showcases/services-page/services-page-carousel-03.jpg",
		"/assets/img/showcases/services-page/services-page-carousel-04.jpg",
	]

	const imagesContent = [
		"/assets/img/showcases/services-page/services-page-image-01.jpg",
	]
	return (
		<article>
			<div className="container">
				<div className="row">
					<div className="col">
						<FormattedMessage
							id="servicesMainArticle.intro.description"
							values={{
								b: chunks => <strong>{chunks}</strong>,
								p: chunks => <p className="lead">{chunks}</p>,
								ul: chunks => <ul>{chunks}</ul>,
								li: chunks => <li>{chunks}</li>
							}} />
					</div>
				</div>
				<div className="row">

					<div className="col-12 col-md-6">

						<h3>
							<FormattedMessage id="servicesMainArticle.benefits.title" />
						</h3>
						<ul>
							<FormattedMessage
								id="servicesMainArticle.benefits.list"
								values={{ li: chunks => <li>{chunks}</li> }} />
						</ul>
					</div>
					<div className="col-12 col-md-6 mb-1 mt-md-4">
						<CarouselSlide id="services-page-carousel1" images={imagesCarousel1} />
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<h3>
							<FormattedMessage id="servicesMainArticle.payment.title" />
						</h3>
					</div>
					<div className="col-12" >
						<ul>
							<FormattedMessage
								id="servicesMainArticle.payment.list"
								values={{ li: chunks => <li>{chunks}</li> }} />
						</ul>
					</div>
				</div>
				<div className="row section-features" >
					<div className="col-12">
						<h3>
							<FormattedMessage id="servicesMainArticle.others.title" />
						</h3>
					</div>
					<div className="col-12 col-sm-4 mb-1 d-flex align-items-stretch">
						<ServiceCard
							title={<FormattedMessage id="servicesMainArticle.others.item-1" />}
							icon="pe-7s-piggy" />
					</div>
					<div className="col-12 col-sm-4 mb-1 d-flex align-items-stretch">
						<ServiceCard
							title={<FormattedMessage id="servicesMainArticle.others.item-2" />}
							icon="pe-7s-note2" />
					</div>
					<div className="col-12 col-sm-4 mb-1 d-flex align-items-stretch">
						<ServiceCard
							title={<FormattedMessage id="servicesMainArticle.others.item-3" />}
							icon="pe-7s-graph1" />
					</div>
					<div className="col-12 my-1 mb-2">
						<FormattedMessage
							id="servicesMainArticle.others.description"
							values={{ p: chunks => <p className="lead text-center">{chunks}</p> }}
						/>
					</div>
				</div>
				<div className="row">
					{
						imagesContent.map((value, index) => {
							return (
								<div className="col-12 mb-1" key={index}>
									<img src={value} alt="" className="img-fluid rounded" />
								</div>
							);
						})
					}
				</div>
			</div>
		</article >
	);
};

export default ServicesMainArticle;