import React, { useContext } from "react";

import { langContext } from "../Context/langContext";

const ChangeLanguaje = (props) => {

	const { changeLanguage, locale } = useContext(langContext);

	const onSelectLanguaje = (e) => {
		changeLanguage(e.target.value)
	};

	return (
		<select
			value={locale}
			className="form-select form-select-sm bg-dark text-light border-0 ps-1"
			onChange={onSelectLanguaje}>
			<option value="es-MX">🇲🇽&emsp;Español</option>
			<option value="en-US">🇺🇸&emsp;English</option>
		</select>
	);
}

export default ChangeLanguaje;