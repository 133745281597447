import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const NavBottom = () => {
  return (
    <section className="container">
      <div className="row text-center d-flex justify-content-between align-items-center">
        <nav className="col-12 col-md-4 my-2">
          <Link className="btn btn-link" to="/">
            <FormattedMessage id="routes.display.home" />
          </Link>
          <a className="btn btn-link" href="assets/docs/Aviso de Pivacidad MEMORIAL.pdf">
            <FormattedMessage id="routes.display.privacyNotice" />
          </a>
        </nav>
        <div className="col-12 col-md-4 my-2 copyright">
          &copy; 2022, Memorial Zimapán, S.C
        </div>
      </div>
    </section>
  );
};
 
export default NavBottom;

