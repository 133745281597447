const ServiceCard = (props) => {
  const { title, children, icon } = props;
  return (
    <div className="card card-green">
      <div className="icon">
        <i className={icon}></i>
      </div>
      <div className="text">
        <h4>{title}</h4>
        <p>{children}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
