import { FormattedMessage } from "react-intl";
import { AboutPage, ContactPage, HomePage, NotFoundPage, ServicesPage } from "../Pages";

const NavRoutes = [
	{
		display: <FormattedMessage id="routes.display.home" />,
		text: "routes.display.home",
		route: "/",
		component: HomePage,
		show: true,
		showBottom: true
	},
	{
		display: <FormattedMessage id="routes.display.services" />,
		text: "routes.display.services",
		route: "/servicios",
		component: ServicesPage,
		show: true
	},
	{
		display: <FormattedMessage id="routes.display.about" />,
		text: "routes.display.about",
		route: "/acerca-de",
		component: AboutPage,
		show: true
	},
	{
		display: <FormattedMessage id="routes.display.contact" />,
		text: "routes.display.contact",
		route: "/contacto",
		component: ContactPage,
		show: true
	},
	{
		display: <FormattedMessage id="routes.display.notFound" />,
		text: "routes.display.notFound",
		route: "*",
		component: NotFoundPage,
		show: false
	},
];

export default NavRoutes;