import { FormattedMessage } from "react-intl";
import { CarouselSlide } from "../../Components";

const ContactMainArticle = () => {

	const carousel1Images = [
		"assets/img/showcases/contact-page/contact-page-img-01.jpg",
		"assets/img/showcases/contact-page/contact-page-img-02.jpg"
	];

	const carousel2Images = [
		"assets/img/showcases/contact-page/contact-page-carousel-01.jpg",
		"assets/img/showcases/contact-page/contact-page-carousel-02.jpg",
		"assets/img/showcases/contact-page/contact-page-carousel-03.jpg",
		"assets/img/showcases/contact-page/contact-page-carousel-04.jpg",
		"assets/img/showcases/contact-page/contact-page-carousel-05.jpg",
	];

	return (
		<article className="container">
			<div className="row mb-3">
				<div className="col-12 mb-2">
					<h3>
						<FormattedMessage id="contactMainArticle.location.title" />
					</h3>
					<address>
						<FormattedMessage
							id="contactMainArticle.location.description"
							values={{ p: chunks => <p>{chunks}</p> }} />
					</address>

				</div>

				<div className="col-12 col-md-4 mb-2">
					<CarouselSlide
						id="carousel-contact-page-01"
						images={carousel1Images} />
				</div>
				<div className="col-12 col-md-8 mb-2">
					<iframe
						title="mapa"
						className="rounded"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d311.287761411902!2d-99.37635378551637!3d20.741187430284526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d3f0ad2e348af5%3A0xb441b19d47c9758e!2sChapultepec%2012%2C%20El%20Sabino%2C%2042330%20Zimap%C3%A1n%2C%20Hgo.!5e0!3m2!1sen!2smx!4v1646874548371!5m2!1sen!2smx"
						style={{ width: "100%", height: "100%", minHeight: "300px" }}
						allowFullScreen=""
						loading="lazy"></iframe>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<CarouselSlide
						id="carousel-contact-page-02"
						images={carousel2Images} />
				</div>
			</div>
		</article>
	);
}

export default ContactMainArticle;