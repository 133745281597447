import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";

const NotFoundPage = () => {
	return (
		<Fragment>
			<main className="container section">
				<div className="container mid-container">
					<div className="row">
						<div className="col">
							<p className="display-3">
								<FormattedMessage id="notFoundPage.title" />
							</p>
							<p className="lead">
								<FormattedMessage id="notFoundPage.subtitle" />
							</p>
							<Link to="/" className="btn btn-success btn-fill">
								<FormattedMessage id="notFoundPage.redirectText" />
							</Link>
						</div>
					</div>
				</div>
			</main>
		</Fragment>
	);
};

export default NotFoundPage;
