import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { NavBottom } from './';
import { ContactDemo } from '../Articles';

const Footer = () => {
  return (
    <footer className="footer">
      <ContactDemo/>
      <NavBottom/>
    </footer>
  );
};

export default Footer;
