import { Fragment } from "react";
import { Header } from "../Components";
import { ContactDemo, HomeMainArticle } from '../Articles';
import { FormattedMessage } from "react-intl";

const HomePage = (props) => {
	return (
		<Fragment>
			<Header backgroundImage="assets/img/showcases/home-page/home-page-header.jpg">
				<h1>
					<FormattedMessage id="homePage.header.title" />
				</h1>
				<p className="h4">
					<FormattedMessage id="homePage.header.subtitle" />
				</p>
			</Header>
			<main className="section container mid-container">
				<HomeMainArticle />
			</main>
		</Fragment>
	);
};

export default HomePage;
