import { FormattedMessage } from "react-intl";

const AboutMainArticle = () => {

	return (
		<article className="container">
			<div className="row mb-3">
				<div className="col-12">
					<h3>
						<FormattedMessage id="aboutMainArticle.general.title" />
					</h3>
					<p>
						<FormattedMessage
							id="aboutMainArticle.general.description"
							values={{ b: chunks => <strong>{chunks}</strong> }} />
					</p>

				</div>
				<div className="col-12">
					<h3><FormattedMessage id="aboutMainArticle.specific.title" /></h3>
					<ul>
						<FormattedMessage
							id="aboutMainArticle.specific.description"
							values={{
								b: chunks => <strong>{chunks}</strong>,
								li: chunks => <li>{chunks}</li>
							}} />
					</ul>
				</div>
				<div className="col-12 col-md-6">
					<h3>
						<FormattedMessage id="aboutMainArticle.mission.title" />
					</h3>
					<p>
						<FormattedMessage
							id="aboutMainArticle.mission.description"
							values={{ b: chunks => <strong>{chunks}</strong> }} />
					</p>
					<h3>
						<FormattedMessage id="aboutMainArticle.vision.title" />
					</h3>
					<p >
						<FormattedMessage
							id="aboutMainArticle.vision.description"
							values={{ b: chunks => <strong>{chunks}</strong> }} />
					</p>
				</div>
				<div className="col-12 col-md-6">
					<img
						className="img-fluid rounded mt-md-3"
						src="assets/img/showcases/about-page/about-page-content-01.jpg" alt="" />
				</div>
				<div className="col-12">
					<h3>
						<FormattedMessage id="aboutMainArticle.values.title" />
					</h3>
					<FormattedMessage
						id="aboutMainArticle.values.description"
						values={{
							b: chunks => <strong>{chunks}</strong>,
							p: chunks => <p>{chunks}</p>
						}} />
				</div>
			</div>
		</article>
	);
}

export default AboutMainArticle;