import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { AboutMainArticle, ContactDemo } from "../Articles";
import { Header } from '../Components';

const AboutPage = () => {

	return (
		<Fragment>
			<Header backgroundImage="assets/img/showcases/about-page/about-page-header.jpg">
				<h1>
					<FormattedMessage id="aboutPage.header.title" />
				</h1>
				<p className="h4">
					<FormattedMessage
						id="aboutPage.header.subtitle"
						values={{ b: chunks => <strong>{chunks}</strong> }} />
				</p>
			</Header>
			<main className="section container mid-container">
				<AboutMainArticle />
			</main>
		</Fragment>
	);
};

export default AboutPage;
