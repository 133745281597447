import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { ContactDemo, ServicesMainArticle } from "../Articles";
import { Header } from "../Components";

const ServicesPage = () => {
	return (
		<Fragment>
			<Header backgroundImage="assets/img/showcases/services-page/services-page-header.jpg">
				<h1>
					<FormattedMessage id="servicesPage.header.title" />
				</h1>
				<p className="h4">
					<FormattedMessage
						id="servicesPage.header.subtitle"
						values={{ b: chunks => <strong>{chunks}</strong> }} />
				</p>
			</Header>
			<main className="section container mid-container">
				<ServicesMainArticle />
			</main>
		</Fragment>
	);
};

export default ServicesPage;
