import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ArticleCard } from "../../Components";

const HomeMainArticle = () => {
	return (
		<article className="container">
			<div className="row mb-3">
				<div className="col text-center">
					<FormattedMessage
						id="homeMainArticle.intro.description"
						values={{ b: chunks => <strong>{chunks}</strong>, p: chunks => <p className="lead">{chunks}</p> }} />
					<Link to="/servicios" className="btn btn-fill btn-success">
						<FormattedMessage
							id="homeMainArticle.intro.redirectText" />
					</Link>
				</div>
			</div>
			<ArticleCard
				title={<FormattedMessage id="homeMainArticle.services.title" />}
				image="assets/img/showcases/home-page/home-page-content-01.jpg">
				<ul>
					<FormattedMessage
						id="homeMainArticle.services.list"
						values={{ li: chunks => <li>{chunks}</li> }} />

				</ul>
				<Link to="/servicios" className="btn btn-fill btn-success">
					<FormattedMessage
						id="homeMainArticle.services.redirectText" />
				</Link>
			</ArticleCard>
			<ArticleCard
				title={<FormattedMessage id="homeMainArticle.about.title" />}
				image="assets/img/showcases/home-page/home-page-content-02.jpg"
				position="right">
				<FormattedMessage
					id="homeMainArticle.about.description"
					values={{ p: chunks => <p>{chunks}</p> }} />
				<Link to="/acerca-de" className="btn btn-fill btn-success">
					<FormattedMessage
						id="homeMainArticle.about.redirectText" />
				</Link>
			</ArticleCard>
		</article>
	);
};

export default HomeMainArticle;