import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { enUsMessages, esMxMessages } from '../Lang'

const langContext = React.createContext();

const LangProvider = props => {
  const [messages, setMessages] = useState(esMxMessages);
  const [locale, setLocale] = useState('es-MX');

  const changeLanguage = (language) => {
    switch (language) {
      case "es-MX":
        setMessages(esMxMessages);
        setLocale("es-MX");
        break;
      case 'en-US':
        setMessages(enUsMessages);
        setLocale("en-US");
        break;
      default:
        setMessages(esMxMessages);
        setLocale("es-MX");
        break;
    }
  }

  useEffect(() => {
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <langContext.Provider value={{ changeLanguage, locale }}>
      <IntlProvider locale={locale} messages={messages}>
        {props.children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export {
  langContext,
  LangProvider
};
