import { FormattedMessage } from "react-intl";
import { Fragment } from "react/cjs/react.production.min";
import { ContactMainArticle } from "../Articles";
import { Header } from "../Components";

const ContactPage = () => {
	return (
		<Fragment>
			<Header backgroundImage="assets/img/showcases/contact-page/contact-page-header.jpg">
				<h1>
					<FormattedMessage id="contactPage.header.title" />
				</h1>
				<p className="h4">
					<FormattedMessage
						id="contactPage.header.subtitle"
						values={{ b: chunks => <strong>{chunks}</strong> }} />
				</p>
			</Header>
			<main className="section container mid-container">
				<ContactMainArticle />
			</main>
		</Fragment>
	);
}

export default ContactPage;
