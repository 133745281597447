const ArticleCard = (props) => {
  const { title, image, position = "left" } = props;

  const figure = (image) => {
    return (
      <div className={`col-12 col-md-6 my-2 mt-md-4`}>
        <img
          src={image}
          className="rounded img-fluid"
          alt=""
          style={{ maxHeight: 300 }}
        />
      </div>
    );
  };

  return (
    <div className="row mb-3">
      {position === "left" && figure(image)}
      <div className="col-12 col-md-6 mb-2">
        <h3>{title}</h3>
        {props.children}
      </div>
      {position === "right" && figure(image)}
    </div>
  );
};

export default ArticleCard;
